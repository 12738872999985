import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-load-btn',
    templateUrl: './load-btn.component.html',
    styleUrls: ['./load-btn.component.scss'],
})
export class LoadBtnComponent implements OnInit {
    @Input() loading = false;
    @Input() color: ThemePalette = undefined;
    @Input() large = false;

    @Input() loadingTitle = 'chargement_en_cours';
    @Input() title = 'refresh';

    @Output() onBtnClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    handleBtnClick() {
        this.onBtnClick.emit();
    }

}
