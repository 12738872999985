export enum UserRolesEnum {
    PUBLIC = 1,
    ENREGISTRE = 2,
    SUPER_UTILISATEUR = 8,
    OBSERVATEUR = 10,
    VALIDATEUR = 11,
    ADMIN = 12,
    DEMO = 13,
    EXPERIMENTATION = 14,
    CARTE = 15,
    FENETRE = 16,
    URBASENSE_CORPORATE = 17,
    INSTALLATEUR = 18,
    SIMPLE_UTILISATEUR = 19,
    RESPONSABLE_COMMERCIAL = 20,
    RESPONSABLE_AGRO = 21,
    RESPONSABLE_EQUIPEMENTS = 22,
    RESPONSABLE_ICAA = 23,
    INTERVENTION_ADMIN = 24,
}
