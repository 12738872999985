import {Injectable} from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import {DiagFicheSite} from '../interfaces/Diag/DiagFicheSite';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private ficheSiteFormSuccessEvent = new BehaviorSubject<DiagFicheSite>(null);
    ficheSiteFormSuccessEvent$ = this.ficheSiteFormSuccessEvent.asObservable();

    private minisenseReplaceEvent = new BehaviorSubject<string>(null);
    minisenseReplaceEvent$ = this.minisenseReplaceEvent.asObservable();

    private minisenseTrameFraicheurReplaceEvent = new BehaviorSubject<boolean>(null);
    minisenseTrameFraicheur$ = this.minisenseTrameFraicheurReplaceEvent.asObservable();

    private interventionAndMissionRefreshEvent = new BehaviorSubject<boolean>(false);
    interventionsAndMissions$ = this.interventionAndMissionRefreshEvent.asObservable();

    constructor() {
    }

    emitFicheSiteFormSuccessEvent(newFicheSite: DiagFicheSite) {
        this.ficheSiteFormSuccessEvent.next(newFicheSite);
    }

    emitTrameFraicheurReplaceEvent(msFreshness: boolean) {
        this.minisenseTrameFraicheurReplaceEvent.next(msFreshness);
    }

    emitMinisenseReplaceEvent(msName: string) {
        this.minisenseReplaceEvent.next(msName);
    }

    emitInterventionAndMissionRefreshEvent() {
        this.interventionAndMissionRefreshEvent.next(true);
    }
}
