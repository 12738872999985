

import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable()
export class UrbasenseHttpRequestInterceptor implements HttpInterceptor {
    constructor(private _router: Router) {}
intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
    
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                
                       
                }
            }),
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    try {
                        if (err.status == 401) {
                            this._router.navigateByUrl('/erreur')

                        }
                        console.log(err)
                    } catch(e) {
               
                    }
                    //log error 
                }
                return of(err);
            }));
    
      }
      
}