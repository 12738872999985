import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import ILanguage from '../interfaces/ILanguage';


@Injectable({
    providedIn: 'root'
})
export class LangageService {

    readonly LANGUAGES: ILanguage[] = [
        {
            key: 'fr',
            name: 'français',
        },
        {
            key: 'en',
            name: 'anglais',
        },
        {
            key: 'de',
            name: 'allemand',
        },
    ];

    private KEY = 'language';

    constructor(private translate: TranslateService) {
        // TODO use this.LANGUAGES keys instead
        translate.addLangs(['fr', 'en', 'de', 'it', 'pt', 'es', 'ar']);
        if (localStorage.getItem(this.KEY)) {
            translate.setDefaultLang(localStorage.getItem(this.KEY));
            translate.use(localStorage.getItem(this.KEY));
        } else {
            const browserLang = translate.getBrowserLang();
            translate.use(browserLang.match(/fr|de|es|pt|it|ar|en/) ? browserLang : 'en');
        }
    }

    public setLangage(language) {
        localStorage.setItem(this.KEY, language);
        this.translate.use(localStorage.getItem(this.KEY));
    }

    /**
     * Get user's prefered language by checking local storage.
     * Fallback to Browser's language
     */
    public getLangage(): string {
        if (localStorage.getItem(this.KEY)) {
            return (localStorage.getItem(this.KEY));
        }
        const browserLang = this.translate.getBrowserLang();
        return (browserLang.match(/fr|de|es|pt|it|ar|en/) ? browserLang : 'en');
    }
}
