<div *ngIf="messageFraicheur" style="color:red;font-size:0.9em;">
{{messageAffiche}}
</div>
<ion-item *ngIf="trame; else notrame">
    <ion-label class="ion-text-wrap">
        <ion-text>
            {{utils.traduireTimestamp(trame.messages.timestamp)}}
        </ion-text>
        <ion-text class="boldSf">
            {{trame.mode_txt}}
        </ion-text>

        <p *ngIf="trame.messages.vbat_mv"> {{'Batterie' | translate}}
            : {{trame.messages.vbat_mv / 1000}} V</p>
        <div *ngIf="!trame.messages.decode.TO_EV1">
            <div *ngFor="let decodedtrame of trame.messages.decode">
                <p *ngIf="isTemperatureSensor(decodedtrame.sonde) && decodedtrame.valeur > 0 && decodedtrame.valeur < 50"
                   class="sensor-data-row"
                >
                    Tmp <i class="material-icons">check</i></p>

                <p *ngIf="isTemperatureSensor(decodedtrame.sonde) && (decodedtrame.valeur < 0 || decodedtrame.valeur > 50)"
                   class="sensor-data-row"
                >
                    Tmp <i class="material-icons">cancel</i></p>

                <p *ngIf="!isTemperatureSensor(decodedtrame.sonde) && decodedtrame.valeur >= 0 && decodedtrame.valeur <= 250"
                   class="sensor-data-row"
                >
                    {{'Sonde' | translate}} {{decodedtrame.sonde}}
                    <i class="material-icons md-18" [style.color]="utils.getCouleurSonde(decodedtrame.valeur)"  #tooltip matTooltipClass="example-tooltip"
                    >fiber_manual_record</i>=> {{decodedtrame.valeur}}</p>

                <p *ngIf="!isTemperatureSensor(decodedtrame.sonde) && ((decodedtrame.valeur > 250) || (decodedtrame.valeur < 0)) "
                   class="sensor-data-row"
                >{{'Sonde' | translate}} {{decodedtrame.sonde}}
                    <i class="material-icons md-18" [style.color]="utils.getCouleurVbat(decodedtrame.valeur)" #tooltip matTooltipClass="example-tooltip"
                    >fiber_manual_record</i>=> {{decodedtrame.valeur}}</p>
            </div>
        </div>
    </ion-label>

    <ion-badge slot="end" [ngStyle]="{'background-color': trame?.messages?.couleur}"
               style="padding:1%;border-radius:5px;color:white">{{'Réseau' | translate}}
        : {{trame?.messages?.reseau}}</ion-badge>
</ion-item>

<app-load-btn
        *ngIf="msName"
        [loading]="loading"
        (onBtnClick)="fetchTrame()"
        [large]="true"
></app-load-btn>

<!--------------------------------------------------------------------->
<!--Reset works. Uncomment if you need to add this functionnality-->
<!--<app-load-btn-->
<!--        *ngIf="initialMsName && initialMsName != msName"-->
<!--        [loading]="loading"-->
<!--        [title]="'reset'"-->
<!--        (onBtnClick)="fetchTrame(initialMsName)"-->
<!--        [large]="true"-->
<!--&gt;</app-load-btn>-->
<!--------------------------------------------------------------------->

<ng-template #notrame>
    <ion-item class="ion-text-wrap">
        <ion-text color="warning">
            {{ (error ? error : 'no_trame') | translate }}
        </ion-text>
    </ion-item>
</ng-template>
