import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of, zip} from 'rxjs';
import {AuthGuard, AuthService} from '@auth0/auth0-angular';
import {IUser} from '../interfaces/IUser';
import {UserService} from '../services/user.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EVService} from '../services/ev.service';
import {UserRolesEnum} from '../enums/UserRolesEnum';

@Injectable({
    providedIn: 'root'
})
export class InstallationGuard extends AuthGuard implements CanActivate {

    constructor(
        private myAuth: AuthService,
        private userService: UserService,
        private router: Router,
        private ev: EVService,
    ) {
        super(myAuth);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        console.log('Entering InstallationGuard');

        return super.canActivate(next, state).pipe(
            mergeMap(isAuthenticated => {
                if (!isAuthenticated) {
                    return of(false);
                }

                if (next.params.mission_id !== null) {
                    return zip(
                        this.myAuth.user$,
                        this.ev.getDetailMission(next.params.mission_id),
                    ).pipe(map(([u, mission]) => {
                        console.log({
                            user: u,
                            mission,
                        });
                        if (null == u || null == mission) {
                            return false;
                        }
                        const user = u as IUser;

                        if (next.queryParams.type_demandeur
                            && next.queryParams.type_demandeur == UserRolesEnum.INTERVENTION_ADMIN) {
                            return this.userService.hasRole(user, UserRolesEnum.INTERVENTION_ADMIN);
                        }
                        console.log('user.email', user.email);
                        return user.email === mission.installateur.mail;
                    }));
                } else {
                    return of(false);
                }
            }),
            catchError(error => {
                console.error('Error in InstallationGuard', error);
                // Gestion ou redirection en cas d'erreur
                this.router.navigate(['/']); // Assurez-vous que le chemin est correct
                return of(false);
            })
        );
    }

}
