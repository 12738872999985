<ion-header >
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
{{minisense}}
</ion-content>
