import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fin-installation',
  templateUrl: './fin-installation.page.html',
  styleUrls: ['./fin-installation.page.scss'],
})
export class FinInstallationPage implements OnInit {
@Input() minisense : any;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
