import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared/shared.module';
import { DeclenchementinterventionsPageRoutingModule } from './declenchementinterventions-routing.module';
import { SelectwithfilterComponent } from '../../components/selectwithfilter/selectwithfilter.component';
import { DeclenchementinterventionsPage } from './declenchementinterventions.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    IonicModule.forRoot(),
    DeclenchementinterventionsPageRoutingModule,
  ],
  declarations: [DeclenchementinterventionsPage]
})
export class DeclenchementinterventionsPageModule {}
