import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthGuard, AuthService} from '@auth0/auth0-angular';
import {Observable, of, zip} from 'rxjs';
import {UserService} from '../services/user.service';
import {IUser} from '../interfaces/IUser';
import {UserRolesEnum} from '../enums/UserRolesEnum';
import {map, mergeMap, catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UrbasenseRoleGuard extends AuthGuard implements CanActivate {

    constructor(
        private myAuth: AuthService, // cannot be named "auth" because of mother class
        private userService: UserService,
        private router: Router,
    ) {
        super(myAuth);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return super.canActivate(next, state).pipe(
            mergeMap((isAuthenticated, index) => {
                console.log('in role guard');
                if (!isAuthenticated) {
                    return of(false);
                }

                return zip(
                    this.myAuth.user$,
                ).pipe(map(([u, mission]) => {
                    const user = u as IUser;
                    const expectedRoles = next.data.roles as UserRolesEnum[] ?? [];
                    console.log({user, expectedRoles});

                    return null != user && this.userService.hasRole(user, expectedRoles);
                }));
            }),
            catchError((err) => {
                console.error(err);
                this.router.navigate(['/']);
                return of(false);
            })
        );
    }

}
