import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detectnavigator',
  templateUrl: './detectnavigator.component.html',
  styleUrls: ['./detectnavigator.component.scss'],
})
export class DetectnavigatorComponent implements OnInit {
  isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  isClosed = false;
  isExternal = false;
  constructor() {

    console.log('navigator.userAgent', navigator.userAgent);

    // Vérifie si l'URL actuelle contient "external"
    this.isExternal = window.location.href.indexOf('external') > -1;

    // Alternative avec includes (plus lisible si supporté par votre environnement)
    // this.isExternal = window.location.href.includes('external');

    console.log('URL contains "external":', this.isExternal);
   }

  ngOnInit() {}


  closeWindows(){
    this.isClosed = true;
  }

}
