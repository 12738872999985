<ion-header>
  <ion-toolbar>
    <ion-searchbar animated="true" mode="ios" [(ngModel)]="searchTerm" [placeholder]="text"></ion-searchbar>
  </ion-toolbar>
  <ion-toolbar *ngIf="multiple">
    <ion-buttons slot="start">
      <ion-button (click)="toggleSelectAll()">{{ allSelected ? 'Tout déselectionner' : 'Selectionner tout' }}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="secondary" (click)="dismissPopover()">Valider la sélection</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item class="ion-border"
    *ngFor="let item of items | filter: searchTerm : key"
    (click)="selectItem(item)"
    [class.selected]="isSelected(item)"
    [disabled]="isItemDisabled(item)"
    tappable>
      <ion-label>
        <h3>{{ item[key] }}</h3>
        <p *ngIf="subkey && isItemDisabled(item)">{{item[subkey]}}</p>
    </ion-label>
      <ion-checkbox *ngIf="multiple" slot="start" [checked]="isSelected(item)"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>
