import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITrame, SensorData} from '../../interfaces/ITrame';
import {EVService} from '../../services/ev.service';
import {Subscription} from 'rxjs';
import {EventService} from '../../services/event.service';
import {TranslateService} from '@ngx-translate/core';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-sondes',
    templateUrl: './sondes.component.html',
    styleUrls: ['./sondes.component.scss'],
})
export class SondesComponent implements OnInit, OnDestroy {
    @Input() trame: ITrame;
    @Input() msName: string;
    initialMsName: string;

    private trameSubscription: Subscription;
    loading = false;
    error = '';
    messageFraicheur = false;
    messageAffiche = "";


    constructor(
        private ev: EVService,
        public utils: UtilsService,
        private eventService: EventService,
        private translator: TranslateService,
    ) {
    }



    ngOnInit() {
        this.initialMsName = this.msName;
        this.trameSubscription = this.eventService.minisenseReplaceEvent$.subscribe((newMsName) => {
            this.fetchTrame(newMsName);
        });
    }

    /**
     * Checks if the given sensor is meant to mesure Temperature
     */
    isTemperatureSensor(sensor: SensorData): boolean {
        if (sensor) {
            return sensor.sonde === 'T';
        }else {
            return false;
        }

    }

    /**
     * Fetches ITrame data based on a given MS device name.
     * Default to this.msName
     * If a new Ms is passed, it will be assigned to this.msName
     */
    fetchTrame(newMsName: string = null): void {

        // We can pass a new MS but we default to this.msName
        this.msName = newMsName ?? this.msName;
        if (this.msName == null) {
            return;
        }

        // Should be able to refresh data
        if (this.trameSubscription) {
            this.trameSubscription.unsubscribe();
        }

        this.loading = true;
        this.trameSubscription = this.ev.v6_check_last_trame_ev(this.msName).subscribe(trame => {


            this.eventService.emitTrameFraicheurReplaceEvent(false);
            if (trame.device_id) {
                this.trame = trame;
                this.error = '';
                if (this.trame.intervalle) {
                    if (this.trame.intervalle < 43200 && this.trame.intervalle > -43200) {
                        this.messageFraicheur = false;
                        this.eventService.emitTrameFraicheurReplaceEvent(true);
                    } else {
                        this.messageFraicheur = true;
                        this.messageAffiche = 'La trame semble trop ancienne, veillez à bien mettre le Minisense sous tension et à rafraichir ensuite.';
                        this.eventService.emitTrameFraicheurReplaceEvent(false);
                    }
                }
            } else {
                this.trame = null;
                this.eventService.emitTrameFraicheurReplaceEvent(false);
                this.messageFraicheur = true;
                this.messageAffiche = 'Il n\'y a aucune trame pour ce Minisense. Vérifiez que le Minisense est bien sous tension et à rafraichir ensuite.';
                this.error = this.translator.instant('no_trame_for', {
                    ms: this.msName,
                });
            }
            this.loading = false;
        });
    }

    ngOnDestroy() {
        if (null != this.trameSubscription) {
            this.trameSubscription.unsubscribe();
        }
    }

}
