<ion-header >
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button id="closeB" (click)="closeModal()">{{'fermer' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>&nbsp;</ion-title>
  </ion-toolbar>
  <ion-item>

    <ion-label class="ion-text-wrap">{{'Permettre à l\'application Urbasense de détecter ma position' | translate}}</ion-label>
  </ion-item>
</ion-header>

<ion-content>



<ion-card>


  <ion-card-content>
    {{'Le message "Impossible de déterminer votre position exacte" s\'affiche ?. Voici les raisons pour lesquelles ce message s\'affiche :' | translate}}
    <ul>
      <li>
        {{'Vous n\'avez pas autorisé votre navigateur Internet pour mobile à détecter votre position.' | translate}}
      </li>
      <li>
        {{'Vous avez désactivé la détection de votre position sur votre téléphone ou tablette.' | translate}}
      </li>
    </ul>
    <ion-segment [(ngModel)]="segment">
      <ion-segment-button value="android">
        <ion-label>Android</ion-label>
      </ion-segment-button>
      <ion-segment-button value="iphone">
        <ion-label>Iphone & Ipad</ion-label>
      </ion-segment-button>
    </ion-segment>



  </ion-card-content>
  <div [ngSwitch]="segment" >
    <div *ngSwitchCase="'android'">
      <ion-list>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <ion-text>
              <h3 class="boldSf">Étape 1 : Activez les services de localisation.</h3>
              <p>1. Ouvrez l'application Chrome Chrome sur votre téléphone ou tablette Android.</p>
              <p>2. En haut à droite, appuyez sur Plus .</p>
              <p>3. Appuyez sur Paramètres.</p>
              <p>4. Dans la section "Paramètres avancés", appuyez sur Paramètres du site.</p>
              <p>5. Appuyez sur "Localisation" puis et activez l'option.</p>
              <p>- Si le message "L'accès aux données de localisation est désactivé pour cet appareil" s'affiche, appuyez sur les mots en bleu puis, puis activez l'accès aux données de localisation dans l'écran suivant.</p>
              <p>- Si la mention "Bloqué" s'affiche sous "Localisation"," appuyez sur Bloqué puis, sur les sites Google qui s'affichent puis, puis sur Effacer et réinitialiser.</p>
              <p>Ouvrez <a href="https://app.urbasense.fr">https://app.urbasense.fr</a> dans le navigateur de votre appareil mobile et actualisez la page si besoin.</p>
            </ion-text>

          </ion-label>
        </ion-item>
      </ion-list>
      </div>

      <div *ngSwitchCase="'iphone'">
        <ion-list>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <ion-text>
                <h3 class="boldSf">Étape 1 : Activez la géolocalisation.</h3>
                <p>1. Ouvrez l'application Réglages de votre iPhone ou iPad.</p>
                <p>2. Appuyez sur Confidentialité puis Services de localisation puis Sites Web Safari.</p>
                <p>3. Sous "Autoriser l'accès aux données de localisation", appuyez sur Lorsque vous utilisez l'application.</p>

              </ion-text>

            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Étape 2 : Autorisez app.urbasense.eu à accéder aux données de localisation actuelles de votre navigateur.</h3>
              <ion-text>
                <h3 class="boldSf">Pour Safari et Chrome</h3>
                <p>1. Ouvrez l'application Réglages de votre iPhone ou iPad.</p>
                <p>2. Appuyez sur Général puis Réinitialiser.</p>
                <p>3. Appuyez sur Réinitialiser localisation et confidentialité.</p>
                <p>4. Si vous y êtes invité, saisissez votre mot de passe.</p>
                <p>5. Un message indique que vos paramètres de localisation et de confidentialité par défaut seront réinitialisés. Appuyez sur Réinitialiser les réglages.</p>
                <p>6. Ouvrez Safari ou Chrome.</p>
                <p>7. Accédez à <a href="https://app.urbasense.eu">https://app.urbasense.eu</a> puis à la rubrique Installation.</p>
                <p>8. Pour autoriser Safari/Chrome à accéder à votre position, appuyez sur Autoriser ou OK.</p>
                <p>9. Pour autoriser l'app Urbasense à accéder à votre position, appuyez sur OK.</p>
              </ion-text>
            </ion-label>
          </ion-item>
        </ion-list>
        </div>
      </div>
</ion-card>
</ion-content>
