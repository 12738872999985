import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-selectwithfilter',
  templateUrl: './selectwithfilter.component.html',
  styleUrls: ['./selectwithfilter.component.scss'],
})
export class SelectwithfilterComponent implements OnInit {


  @Input() title: string = "";
  @Input() items: {name: string, icon: string}[] = [];
  @Input() subkey: string = "";
  @Input() key: string = "";
  @Input() id: string = "";
  @Input() text: string = "";
  @Input() multiple: boolean = false;
  @Input() target: boolean = false;
  @Input() selectedItemsInput: any = [];


  public searchTerm = '';
  public selectedItems: any[] = [];
  public allSelected: boolean = false;
  constructor(
    private popoverController: PopoverController ) { }

    ngOnInit(){
      if (this.selectedItemsInput) {
        console.log('we have selected',this.selectedItemsInput);
        this.selectedItems = this.selectedItemsInput;
        console.log('selectedItems',this.selectedItems);
      }
    }


  selectItem(item) {
    if (this.multiple) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
    } else {
      this.popoverController.dismiss({
        'selectedItem': item,
        'type': this.target
      });
    }
  }

  isSelected(item) {
    return this.selectedItems.indexOf(item) > -1;
  }


  toggleSelectAll() {
    if (this.allSelected) {
      this.selectedItems = [];
    } else {
      this.selectedItems = [...this.items];
    }
    this.allSelected = !this.allSelected;
  }

  dismissPopover() {
    this.popoverController.dismiss({
      'selectedItems': this.selectedItems,
      'type': this.target
    });
  }

  isItemDisabled(item) {
    return item[this.subkey] === 'INTERV_STATUT_ACCEPTEE' || item[this.subkey] === 'INTERV_STATUT_DEMANDEE' || item[this.subkey] === 'INTERV_STATUT_DEMANDE';
  }

}
