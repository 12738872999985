export const myEssenceList = [
  { code: 'LAGsp.', txt: ' Lagerstroemia sp.' },
  { code: 'Adef', txt: 'À définir' },
  { code: 'ABIcon', txt: 'Abies concolor' },
  { code: 'ABInor', txt: 'Abies nordmanniana' },
  { code: 'ACAnov', txt: 'Acaena novae-zelandiae' },
  { code: 'ACEcam', txt: 'Acer campestre' },
  { code: 'ACEcam \'E\'', txt: 'Acer campestre \'Elsrijk\'' },
  { code: 'ACEcap', txt: 'Acer cappadocicum' },
  { code: 'ACEgri', txt: 'Acer griseum' },
  { code: 'ACEmon', txt: 'Acer monspessulanum' },
  { code: 'ACEneg', txt: 'Acer negundo' },
  { code: 'ACEopa', txt: 'Acer opalus' },
  { code: 'ACEpal', txt: 'Acer palmatum' },
  { code: 'ACEpla', txt: 'Acer platanoides' },
  { code: 'ACEpla', txt: 'Acer platanoides' },
  { code: 'ACEpse', txt: 'Acer pseudoplatanus' },
  { code: 'ACErub', txt: 'Acer rubrum' },
  { code: 'ACEsp.', txt: 'Acer sp.' },
  { code: 'ACEfre', txt: 'Acer x freemanii' },
  { code: 'ACHumb', txt: 'Achillea umbellata' },
  { code: 'ADOmer', txt: 'Adonidia merrillii' },
  { code: 'AEScar', txt: 'Aesculus carnea' },
  { code: 'AEShip', txt: 'Aesculus hippocastanum' },
  { code: 'AESsp.', txt: 'Aesculus sp.' },
  { code: 'AETgra', txt: 'Aethionema grandiflorum' },
  { code: 'ALBjub', txt: 'Albizia julibrissin' },
  { code: 'ALNcor', txt: 'Alnus cordata' },
  { code: 'ALNglu', txt: 'Alnus glutinosa' },
  { code: 'ALNinc', txt: 'Alnus incana' },
  { code: 'ALNspa', txt: 'Alnus spaethii' },
  { code: 'ALYmon', txt: 'Alyssum montanum' },
  { code: 'AMEarb', txt: 'Amelanchier arborea' },
  { code: 'AMEcan', txt: 'Amelanchier canadensis' },
  { code: 'AMElam', txt: 'Amelanchier lamarckii' },
  { code: 'AMEsp', txt: 'Amelanchier sp' },
  { code: 'ANNUELLE', txt: 'Annuelle' },
  { code: 'ANTdio', txt: 'Antennaria dioica' },
  { code: 'ANThis', txt: 'Antirrhinum hispanicum' },
  { code: 'ARBsp.', txt: 'Arbustes - espèces multiples' },
  { code: 'ARBcouv', txt: 'Arbustes et couvre-sol' },
  { code: 'ARBune', txt: 'Arbutus unedo' },
  { code: 'ARE', txt: 'Arecaceae' },
  { code: 'BAM', txt: 'Bambuseae' },
  { code: 'BEA', txt: 'Beaucarnea' },
  { code: 'BETerm', txt: 'Betula ermanii' },
  { code: 'BETnig', txt: 'Betula nigra' },
  { code: 'BETpap', txt: 'Betula papyrifera' },
  { code: 'BETpen', txt: 'Betula pendula' },
  { code: 'BETpub', txt: 'Betula pubescens' },
  { code: 'BETsp.', txt: 'Betula sp.' },
  { code: 'BETuti', txt: 'Betula utilis' },
  { code: 'BETver', txt: 'Betula verrucosa' },
  { code: 'BROsp.', txt: 'Broussonetia sp.' },
  { code: 'BUXsp.', txt: 'Buxus sp.' },
  { code: 'CALgra', txt: 'Calamintha grandiflora' },
  { code: 'CALinv', txt: 'Callirhoe involucrata' },
  { code: 'CALdec', txt: 'Calocedrus decurrens' },
  { code: 'CAM', txt: 'Camelia' },
  { code: 'CANodo', txt: 'Cananga odorata' },
  { code: 'CARbro', txt: 'Carex bronze' },
  { code: 'CARbet', txt: 'Carpinus betulus' },
  { code: 'CARori', txt: 'Carpinus orientalis' },
  { code: 'CARova', txt: 'Carya ovata' },
  { code: 'CATbig', txt: 'Catalpa bignonioides' },
  { code: 'CEDatl', txt: 'Cedrus atlantica' },
  { code: 'CELaus', txt: 'Celtis australis' },
  { code: 'CELkor', txt: 'Celtis koraiensis' },
  { code: 'CELlae', txt: 'Celtis laevigata' },
  { code: 'CELsin', txt: 'Celtis sinensis' },
  { code: 'CELsp.', txt: 'Celtis sp.' },
  { code: 'CENbel', txt: 'Centaurea bella' },
  { code: 'CERAsil', txt: 'Ceratonia siliqua' },
  { code: 'CERjap', txt: 'Cercidiphyllum japonicum' },
  { code: 'CERsil', txt: 'Cercis siliquastrum' },
  { code: 'CHAlaw', txt: 'Chamaecyparis lawsoniana' },
  { code: 'CHAexc', txt: 'Chamaerops excelsa' },
  { code: 'CHAhum', txt: 'Chamaerops humilis' },
  { code: 'CHItas', txt: 'Chitalpa tashkentensis' },
  { code: 'CHRlut', txt: 'Chrysalidocarpus lutescens' },
  { code: 'CLAlut', txt: 'Cladastris lutea' },
  { code: 'CLEsp', txt: 'Clematis sp.' },
  { code: 'CONsab', txt: 'Convolvulus sabatius' },
  { code: 'CORflo', txt: 'Cornus florida' },
  { code: 'CORmas', txt: 'Cornus mas' },
  { code: 'CORave', txt: 'Corylus avellana' },
  { code: 'CORcol', txt: 'Corylus colurna' },
  { code: 'CORcol', txt: 'Corylus colurna' },
  { code: 'COTcog', txt: 'Cotinus coggygria' },
  { code: 'COTsp', txt: 'Cotoneaster sp' },
  { code: 'COThis', txt: 'Cotula hispida' },
  { code: 'COUsp.', txt: 'Couvre-sol sp.' },
  { code: 'CRAcar', txt: 'Crataegus carrieri' },
  { code: 'CRAmon', txt: 'Crataegus monogyna' },
  { code: 'CUPsem', txt: 'Cupressus sempervirens' },
  { code: 'CYDvul', txt: 'Cydonia vulgaris' },
  { code: 'DAHrou', txt: 'Dahlia rouge' },
  { code: 'DAUcar', txt: 'Daucus carota' },
  { code: 'DELcoo', txt: 'Delosperma cooperi' },
  { code: 'DIAcar', txt: 'Dianthus carthusianorum' },
  { code: 'DIAplu', txt: 'Dianthus plumarius' },
  { code: 'DIOlot', txt: 'Diospyros lotus' },
  { code: 'DIV', txt: 'Divers' },
  { code: 'DORhir', txt: 'Dorycnium hirsutum' },
  { code: 'DRAaiz', txt: 'Draba aizoides' },
  { code: 'DRAmar', txt: 'Dracaena marginata' },
  { code: 'EMPvid', txt: 'Emplacement vide' },
  { code: 'ERIgla', txt: 'Erigeron glaucus' },
  { code: 'EROman', txt: 'Erodium manescavii' },
  { code: 'ESPmul', txt: 'Espèces multiples' },
  { code: 'EUCgun', txt: 'Eucalyptus gunnii' },
  { code: 'EUCgun', txt: 'Eucalyptus gunnii' },
  { code: 'EUOala', txt: 'Euonymus alatus' },
  { code: 'FAGsp.', txt: 'Fagus sp.' },
  { code: 'FAGsyl', txt: 'Fagus sylvatica' },
  { code: 'FICcar', txt: 'Ficus Carica' },
  { code: 'FICrel', txt: 'Ficus religiosa' },
  { code: 'FONphi', txt: 'Fontanesia phillyreoides' },
  { code: 'FRAame', txt: 'Fraxinus americana' },
  { code: 'FRAang', txt: 'Fraxinus angustifolia' },
  { code: 'FRAexc', txt: 'Fraxinus excelsior' },
  { code: 'FRAorn', txt: 'Fraxinus ornus' },
  { code: 'FRApen', txt: 'Fraxinus pensylvanicum' },
  { code: 'FRAsp.', txt: 'Fraxinus sp.' },
  { code: 'GAZMTP', txt: 'Gazon MTP' },
  { code: 'GAZpla', txt: 'Gazon plaqué' },
  { code: 'GAZray', txt: 'Gazon ray grass' },
  { code: 'GERcan', txt: 'Geranium cantabrigiense' },
  { code: 'GERdec', txt: 'Geranium decora framboise' },
  { code: 'GERzon', txt: 'Geranium zonal pulsar violet' },
  { code: 'GINbil', txt: 'Ginkgo biloba' },
  { code: 'GINbla', txt: 'Ginkgo biloba \'blagon\'' },
  { code: 'GLEtri', txt: 'Gleditsia triacanthos' },
  { code: 'GRAsp.', txt: 'Graminées sp.' },
  { code: 'GYPcer', txt: 'Gypsophila cerastoides' },
  { code: 'HALsp.', txt: 'Halesia sp.' },
  { code: 'HELita', txt: 'Helichrysum italicum' },
  { code: 'HEUlim', txt: 'Heudhora lime' },
  { code: 'HIBsp.', txt: 'Hibiscus sp.' },
  { code: 'HYD', txt: 'Hydrangea' },
  { code: 'HYSoff', txt: 'Hyssopus officinalis' },
  { code: 'ILEaqu', txt: 'Ilex aquifolium' },
  { code: 'IRIlut', txt: 'Iris lutescens' },
  { code: 'ISOaxi', txt: 'Isotoma axillaris' },
  { code: 'JACmar', txt: 'Jacobaea maritima' },
  { code: 'JUGreg', txt: 'Juglans regia' },
  { code: 'KOEpan', txt: 'Koelreuteria paniculata' },
  { code: 'LAGind', txt: 'Lagerstroemia indica' },
  { code: 'LAUnob', txt: 'Laurus nobilis' },
  { code: 'LIGibo', txt: 'Ligustrum ibota' },
  { code: 'LIGluc', txt: 'Ligustrum lucidum' },
  { code: 'LIQsp.', txt: 'Liquidambar sp.' },
  { code: 'LIQsp.', txt: 'Liquidambar sp.' },
  { code: 'LIQsty', txt: 'Liquidambar styraciflua' },
  { code: 'LIRsp.', txt: 'Liriodendron sp.' },
  { code: 'LIRtul', txt: 'Liriodendron tulipifera' },
  { code: 'LONnit', txt: 'Lonicera nitida' },
  { code: 'MAGgal', txt: 'Magnolia galaxie' },
  { code: 'MAGgra', txt: 'Magnolia grandiflora' },
  { code: 'MAGkob', txt: 'Magnolia kobus' },
  { code: 'MAGsou', txt: 'Magnolia soulangeana' },
  { code: 'MALald', txt: 'Malus aldenhamensis' },
  { code: 'MALbut', txt: 'Malus butterball' },
  { code: 'MALeve', txt: 'Malus evereste' },
  { code: 'MALsie', txt: 'Malus sieboldii' },
  { code: 'Mal', txt: 'Malus sp.' },
  { code: 'MALsyl', txt: 'Malus sylvestris' },
  { code: 'MALtor', txt: 'Malus toringo' },
  { code: 'MALtri', txt: 'Malus trilobata' },
  { code: 'MALtsc', txt: 'Malus tschonoskii' },
  { code: 'MATtch', txt: 'Matricaria tchihatchewii' },
  { code: 'MELaze', txt: 'Melia azedarach' },
  { code: 'MENreq', txt: 'Mentha requienii' },
  { code: 'MESger', txt: 'Mespilus germanica' },
  { code: 'METsp.', txt: 'Metasequoia sp.' },
  { code: 'MORalb', txt: 'Morus alba' },
  { code: 'MORkag', txt: 'Morus kagayamae' },
  { code: 'MYOpar', txt: 'Myoporum parviflorum' },
  { code: 'OENspe', txt: 'Oenothera speciosa' },
  { code: 'OLEeur', txt: 'Olea europaea' },
  { code: 'ORIvul', txt: 'Origanum vulgare' },
  { code: 'OSTcar', txt: 'Ostrya carpinifolia' },
  { code: 'OSTsp.', txt: 'Ostrya sp.' },
  { code: 'PARper', txt: 'Parrotia persica' },
  { code: 'PARsp.', txt: 'Parrotia sp.' },
  { code: 'POApra', txt: 'Pâturin des prés' },
  { code: 'PAUsp.', txt: 'Paulownia sp.' },
  { code: 'PAUtom', txt: 'Paulownia tomentosa' },
  { code: 'POLtom', txt: 'paulownia tomentosa' },
  { code: 'PETsax', txt: 'Petrorhagia saxifraga' },
  { code: 'PHEamu', txt: 'Phellodendron amurense' },
  { code: 'PHOxfr', txt: 'Photinia x fraseri' },
  { code: 'PHYcan', txt: 'Phyla canescens' },
  { code: 'PHYaur', txt: 'Phyllostachys aurea' },
  { code: 'PINcon', txt: 'Pinus contorta' },
  { code: 'PINden', txt: 'Pinus densiflora' },
  { code: 'PINhal', txt: 'Pinus halepensis' },
  { code: 'PINmug', txt: 'Pinus mugo' },
  { code: 'PINnig', txt: 'Pinus nigra' },
  { code: 'PINnig', txt: 'Pinus nigra' },
  { code: 'PINpin', txt: 'Pinus pinea' },
  { code: 'PINsyl', txt: 'Pinus sylvestris' },
  { code: 'PINunc', txt: 'Pinus uncinata' },
  { code: 'PISter', txt: 'Pistacia terebinthus' },
  { code: 'PIT', txt: 'Pittosporum' },
  { code: 'PLAocc', txt: 'Platane occidentalis' },
  { code: 'PLAace', txt: 'Platanus acerifolia' },
  { code: 'PLAhis', txt: 'Platanus hispanica' },
  { code: 'PLAori', txt: 'Platanus orientalis' },
  { code: 'PLApla', txt: 'Platanus platanor' },
  { code: 'PLAsp.', txt: 'Platanus sp.' },
  { code: 'PLAval', txt: 'Platanus Vallis Clausa' },
  { code: 'POPnig', txt: 'Populus nigra' },
  { code: 'POPtre', txt: 'Populus tremula' },
  { code: 'PRA', txt: 'Prairie' },
  { code: 'PRUsun', txt: 'Prunus \'Sunset Boulevard\'' },
  { code: 'PRUavi', txt: 'Prunus avium' },
  { code: 'PRUhil', txt: 'Prunus hillieri' },
  { code: 'PRUmaa', txt: 'Prunus maackii' },
  { code: 'PRUpad', txt: 'Prunus padus' },
  { code: 'PRUran', txt: 'Prunus sargentii \'rancho\'' },
  { code: 'PRUser', txt: 'Prunus serrulata' },
  { code: 'PRUser', txt: 'Prunus serrulata' },
  { code: 'PRUsp.', txt: 'Prunus sp.' },
  { code: 'PRUsub', txt: 'Prunus subhirtella' },
  { code: 'PRUxye', txt: 'Prunus x yedoensis' },
  { code: 'PRUyed', txt: 'Prunus yedoensis' },
  { code: 'PTEfra', txt: 'Pterocarya fraxinifolia' },
  { code: 'PYRcal', txt: 'Pyrus calleryana' },
  { code: 'PYRcom', txt: 'Pyrus communis' },
  { code: 'PYRcor', txt: 'Pyrus cordata' },
  { code: 'PYRser', txt: 'Pyrus serrulata' },
  { code: 'PYRsp.', txt: 'Pyrus sp.' },
  { code: 'QUEacu', txt: 'Quercus acutissima' },
  { code: 'QUEamy', txt: 'Quercus amygdalus' },
  { code: 'QUEcas', txt: 'Quercus castaneifolia' },
  { code: 'QUEcer', txt: 'Quercus cerris' },
  { code: 'QUEcoc', txt: 'Quercus coccinea' },
  { code: 'QUEhis', txt: 'Quercus hispanica' },
  { code: 'QUEile', txt: 'Quercus ilex' },
  { code: 'QUEimb', txt: 'Quercus imbricaria' },
  { code: 'QUEmyr', txt: 'Quercus myrsinifolia' },
  { code: 'QUEpal', txt: 'Quercus palustris' },
  { code: 'QUEpet', txt: 'Quercus petraea' },
  { code: 'QUEphe', txt: 'Quercus phellos' },
  { code: 'QUEpub', txt: 'Quercus pubescens' },
  { code: 'QUErob', txt: 'Quercus robur' },
  { code: 'QUErub', txt: 'Quercus rubra' },
  { code: 'QUEsp.', txt: 'Quercus sp.' },
  { code: 'QUEsub', txt: 'Quercus suber' },
  { code: 'QUEtro', txt: 'Quercus trojana' },
  { code: 'RHO', txt: 'Rhododendron' },
  { code: 'ROBpse', txt: 'Robinia pseudo-acacia' },
  { code: 'ROSsp.', txt: 'Rosa sp.' },
  { code: 'ROSoff', txt: 'Rosmarinus officinalis' },
  { code: 'SALalb', txt: 'Salix alba' },
  { code: 'SALmat', txt: 'Salix matsudana' },
  { code: 'SALvim', txt: 'Salix viminalis' },
  { code: 'SALcha', txt: 'Salvia chamaedryoides' },
  { code: 'SAN', txt: 'Sansevieria' },
  { code: 'SCAcre', txt: 'Scabiosa cretica' },
  { code: 'SCHact', txt: 'Schefflera actinophylla' },
  { code: 'SCHarb', txt: 'Schefflera arboricola' },
  { code: 'SEDgyp', txt: 'Sedum gypsicola' },
  { code: 'SEDsed', txt: 'Sedum sediforme' },
  { code: 'SEQgig', txt: 'Sequoiadendron giganteum' },
  { code: 'SOPjap', txt: 'Sophora japonica' },
  { code: 'SORari', txt: 'Sorbus aria' },
  { code: 'SORauc', txt: 'Sorbus aucuparia' },
  { code: 'SORdom', txt: 'Sorbus domestica' },
  { code: 'SORint', txt: 'Sorbus intermedia' },
  { code: 'SORthu', txt: 'Sorbus X Thuringiaca' },
  { code: 'STEsec', txt: 'Stenotaphrum secundatum' },
  { code: 'SYRvul', txt: 'Syringa vulgaris' },
  { code: 'TAGpat', txt: 'Tagetes patula' },
  { code: 'TAXdis', txt: 'Taxodium distichum' },
  { code: 'TAXbac', txt: 'Taxus baccata' },
  { code: 'TETdan', txt: 'Tetradium daniellii' },
  { code: 'TEUcha', txt: 'Teucrium chamaedrys' },
  { code: 'THUpli', txt: 'Thuja plicata' },
  { code: 'THUpli', txt: 'Thuja plicata' },
  { code: 'THYcit', txt: 'Thymus citriodorus' },
  { code: 'THYpra', txt: 'Thymus praecox' },
  { code: 'THYpse', txt: 'Thymus pseudolanuginosus' },
  { code: 'THYser', txt: 'Thymus serpyllum' },
  { code: 'TILame', txt: 'Tilia americana' },
  { code: 'TILcor', txt: 'Tilia cordata' },
  { code: 'TILeuc', txt: 'Tilia euchlora' },
  { code: 'TILeur', txt: 'Tilia europaea' },
  { code: 'TILpal', txt: 'Tilia pallida' },
  { code: 'TILpla', txt: 'Tilia platyphyllos' },
  { code: 'TILpla', txt: 'Tilia platyphyllos' },
  { code: 'TILtom', txt: 'Tilia tomentosa' },
  { code: 'TOOsin', txt: 'Toona sinensis' },
  { code: 'ULMlut', txt: 'Ulmus lutece' },
  { code: 'ULMmin', txt: 'Ulmus minor' },
  { code: 'ULMres', txt: 'Ulmus resista' },
  { code: 'ULMsp.', txt: 'Ulmus sp.' },
  { code: 'VIBlan', txt: 'Viburnum lantana' },
  { code: 'VIBtin', txt: 'Viburnum tinus' },
  { code: 'VIVACE', txt: 'Vivace' },
  { code: 'ZELcar', txt: 'Zelkova carpinifolia' },
  { code: 'ZELcre', txt: 'Zelkova crenata' },
  { code: 'ZELser', txt: 'Zelkova serrata' }
];

export const dispoTypeList = [{
  code: '37',
  label: 'Tensio: Défaut 3S (1 à 3)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/37.jpg',
}, {
  code: '1',
  label: 'Tensio: Arbre 3S (sondes 1 à 3)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/1.jpg',
}, {
  code: '39',
  label: 'Tensio: Jardinière 1S',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/39.jpg',
}, {
  code: '2',
  label: 'Tensio: Arbre 3S (sondes 4 à 6)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/2.jpg',
}, {
  code: '32',
  label: 'Tensio: Arbre 6S (sondes 1 à 6)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/32.jpg',
}, {
  code: '31',
  label: 'Tensio: vivaces 20cm (1 à 3)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/31.jpg',
}, {
  code: '26',
  label: 'Tensio: Terrain sport 6S (1 à 6)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/26.jpg',
}, {
  code: '47',
  label: 'EV: Programmateur: Voie 1',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/47.jpg',
}, {
  code: '36',
  label: 'Tensio: Arbuste 3S (sonde 1 à 3)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/36.jpg',
}, {
  code: '48',
  label: 'EV: Programmateur: Voie 2',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/48.jpg',
}, {
  code: '49',
  label: 'EV: Programmateur: Voie 3',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/49.jpg',
}, {
  code: '28',
  label: 'Hauteur d\'eau 25 (sonde 1)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/28.jpg',
}, {
  code: '40',
  label: 'Hauteur d\'eau 80cm (1s)',
  img: 'https://arbre.urbasense.eu/images/urbasense/icones/dispositifs/40.jpg',
}];


export const arroTypeList = [
  { code: 'AR_auto', code_unique: null, txt: 'Automatique' },
  { code: 'AR_manu', code_unique: null, txt: 'Manuel' },
  { code: 'autoMS', code_unique: 'SUJET_arrosage_autoMS', txt: 'MS télépiloté' }
];

export const yearsList = [
  { code: '2001', code_unique: null, txt: '2001' },
  { code: '2009', code_unique: null, txt: '2009' },
  { code: '2012', code_unique: null, txt: '2012' },
  { code: '2013', code_unique: null, txt: '2013' },
  { code: '2014', code_unique: null, txt: '2014' },
  { code: '2015', code_unique: null, txt: '2015' },
  { code: '2016', code_unique: null, txt: '2016' },
  { code: '2017', code_unique: null, txt: '2017' },
  { code: '2018', code_unique: null, txt: '2018' },
  { code: '2019', code_unique: null, txt: '2019' },
  { code: '2020', code_unique: null, txt: '2020' },
  { code: '2021', code_unique: 'SUJET_annee_2021', txt: '2021' }
];

export const doseList = [
  { code: '1', label: '1min' },
  { code: '2', label: '2min' },
  { code: '3', label: '3min' },
  { code: '4', label: '4min' },
  { code: '5', label: '5min' },
  { code: '6', label: '6min' },
  { code: '7', label: '7min' },
  { code: '8', label: '8min' },
  { code: '9', label: '9min' },
  { code: '10', label: '10min' },
  { code: '11', label: '11min' },
  { code: '12', label: '12min' },
  { code: '13', label: '13min' },
  { code: '14', label: '14min' },
  { code: '15', label: '15min' },
  { code: '20', label: '20min' },
  { code: '25', label: '25min' },
  { code: '30', label: '30min' },
  { code: '35', label: '35min' },
  { code: '40', label: '40min' },
  { code: '45', label: '45min' },
  { code: '50', label: '50min' },
  { code: '55', label: '55min' },
  { code: '60', label: '1h' },
  { code: '75', label: '1h15' },
  { code: '90', label: '1h30' },
  { code: '120', label: '2h' },
  { code: '150', label: '2h30' },
  { code: '180', label: '3h' },
  { code: '240', label: '4h' },
  { code: '300', label: '5h' },
  { code: '360', label: '6h' },
];
