
  <app-page-header></app-page-header>

<ion-item *ngIf="!geolocAvailable">
  <ion-label class="ion-text-wrap">
  {{"Il semblerait que votre géolocation soit désactivée, n'hésitez pas à consulter l'aide afin de l'activer." | translate}}
  </ion-label>
  <ion-spinner *ngIf="checkGeoloc"></ion-spinner>
  <ion-button (click)="checkPosition()"><ion-icon name="refresh-outline"></ion-icon>Vérifier si la localisation est activée</ion-button>
  <ion-button (click)="utils.presentModalHelp()"><ion-icon name="help-circle-outline"></ion-icon>{{"Voir l'aide" | translate}}</ion-button>
</ion-item>
<ion-content>

<mat-horizontal-stepper linear [selectedIndex]="selectedStepIndex"
(selectionChange)="stepChangeCallback($event)" #lestepper  theme="grey" >
<mat-step label="" [stepControl]="frmStepOne" theme="dark" >
    <choix-minisense [parent]="this" (move)="move($event)" (choixminisense)="choixminisense($event)" #step1 ></choix-minisense>

  </mat-step>


    <mat-step label="" [stepControl]="frmStepTwo">
      <test-reseau [parent]="this" (changeReseau)="testReseau($event)" [parentSubject]="parentSubject" #step2></test-reseau>
    </mat-step>
    <mat-step label="" [stepControl]="frmStepThree">
      <gps [parent]="this"  [parentSubject]="parentSubject" #step3></gps>
    </mat-step>

    <mat-step  label="" [stepControl]="frmStepFour">
      <choix-projet  (listchange)="listSiteChange($event)" [parent]="this"  [parentSubject]="parentSubject" #step4></choix-projet>
    </mat-step>
    <mat-step label="" [stepControl]="frmStepFive">
      <choix-vegetal (changementChoixVegetal)="parent_choixVegetal($event)" [parent]="this" [parentSubject]="parentSubject" #step5></choix-vegetal>
    </mat-step>
    <mat-step label="" [stepControl]="frmStepSix">
      <choix-type-dispo (changementTypeDispo)="parent_choixDispo($event)"  [parentSubject]="parentSubject" [parent]="this" #step6></choix-type-dispo>
    </mat-step>
    <mat-step label="" [stepControl]="frmStepSeven">
      <photo-first (photos_done)="parent_photos_done($event)" [parentSubject]="parentSubject" #step7 [parent]="this"></photo-first>
    </mat-step>
    <mat-step label="" [stepControl]="frmStepEight">
      <photo-two (end)="fin($event)" (photos_done)="parent_photos_two_done($event)" [parentSubject]="parentSubject" #step8 [parent]="this"></photo-two>
    </mat-step>
</mat-horizontal-stepper>
<button color="warn" *ngIf="isScan" mat-flat-button mat-button (click)="cancelScan()">Annuler</button>
</ion-content>

