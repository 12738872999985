<button
        [disabled]="loading"
        mat-button
        [color]="color"
        (click)="handleBtnClick()"
        [ngClass]="{'large-btn': large}"
>
    <ion-icon
            [ngClass]="{'spin': loading}"
            name="refresh-outline"></ion-icon>
    {{ (loading ? loadingTitle : title) | translate }}
</button>
