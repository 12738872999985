import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-aidegeoloc',
  templateUrl: './aidegeoloc.page.html',
  styleUrls: ['./aidegeoloc.page.scss'],
})
export class AidegeolocPage implements OnInit {
  segment = 'android';
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {

  }

  closeModal() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
